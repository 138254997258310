import { Component, Vue, Prop, Model, Watch } from "vue-property-decorator";
import { phoneMasks } from "@config/phone";
import { getFlagByCountry, uniqCid } from "@helpers";
import { profileModule } from "@store/namespaces";
import { ProfileGetters } from "@store/modules/profile/types";

import MaskTextField from "@/components/MaskTextField/MaskTextField.vue";

@Component({
  inheritAttrs: false,
  components: {
    MaskTextField,
  },
})
export default class PhoneNumberTextField extends Vue {
  @profileModule.Getter("profileLanguage")
  private readonly profileLanguageGetter!: ProfileGetters["profileLanguage"];

  @Model("accept", { type: String, required: true })
  private readonly value!: string;
  @Prop({ type: String, required: true }) private readonly country!: string;
  @Prop({ type: String, required: true }) private readonly prefix!: string;
  @Prop({ type: Boolean, default: false }) private readonly outlined!: boolean;
  @Prop({ type: Boolean, default: false }) private readonly dense!: boolean;
  @Prop({ type: Boolean, default: false })
  private readonly hideDetails!: boolean;
  @Prop({ type: String, default: "#ffffff" })
  private readonly backgroundColor!: string;

  private showedMenu = false;
  private phoneMaskIndex = 0;
  private searchText = "";

  private get activatorId() {
    return `phone-number-${uniqCid().toString()}`;
  }

  private get langCode() {
    return this.profileLanguageGetter(this.$vuetify.lang.current);
  }

  private get phoneMasks() {
    return phoneMasks;
  }

  private get filteredPhoneMasks() {
    const searchText = this.searchText.trim().toLowerCase();

    return this.phoneMasks.filter(({ name_ru, name_en }) => {
      return (
        name_ru.toLowerCase().includes(searchText) ||
        name_en.toLowerCase().includes(searchText)
      );
    });
  }

  private get activePhoneMask() {
    return this.phoneMasks[this.phoneMaskIndex];
  }

  @Watch("showedMenu")
  private onChangeShowMenu() {
    this.searchText = "";
  }

  private getFlagByCountry(country: string) {
    return getFlagByCountry(country);
  }

  private toggleMenu(showed = !this.showedMenu) {
    this.showedMenu = showed;
  }

  private setPhoneMask({ code }: { code: string }) {
    if (!code) return;

    this.toggleMenu(false);

    this.phoneMaskIndex = this.phoneMasks.findIndex(
      (item) => item.code === code
    );
  }

  private mounted() {
    this.setPhoneMask({
      code: this.country,
    });

    this.$watch(
      () => {
        return this.activePhoneMask.code;
      },
      (value) => {
        this.$emit("update:country", value);
      },
      {
        immediate: true,
      }
    );

    this.$watch(
      () => {
        return this.activePhoneMask.phonePrefix;
      },
      (value) => {
        this.$emit("update:prefix", value);
      },
      {
        immediate: true,
      }
    );
  }
}
