import IMask from "imask";
import { Component, Vue, Ref, Model, Prop } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
})
export default class MaskTextField extends Vue {
  @Ref("textField") private readonly textFieldRef?: {
    lazyValue: string;
    $el: HTMLDivElement;
  };
  @Prop({ type: Object, default: () => ({}) })
  private readonly options!: IMask.AnyMaskedOptions;
  @Model("accept", { type: String, required: true }) private value!: string;

  private isCompleted = false;

  private mounted() {
    const inputEl = this.textFieldRef?.$el.querySelector("input");

    if (!inputEl || !this.textFieldRef) return;

    const maskedInput = IMask(inputEl, { ...this.options });

    maskedInput.on("complete", () => {
      this.isCompleted = true;
    });

    maskedInput.unmaskedValue = this.value;

    this.textFieldRef.lazyValue = maskedInput.value;

    maskedInput.updateValue();

    maskedInput.on("accept", () => {
      this.isCompleted = false;

      if (this.textFieldRef) {
        this.textFieldRef.lazyValue = maskedInput.value;
      }

      this.$emit("accept", maskedInput.unmaskedValue);
    });

    const onBlur = () => {
      if (!this.textFieldRef) return;

      maskedInput.unmaskedValue = this.value;
      this.textFieldRef.lazyValue = maskedInput.value;

      maskedInput.updateValue();
    };

    inputEl.addEventListener("blur", onBlur);

    this.$watch(
      () => {
        return [this.options.mask].join("-");
      },
      () => {
        maskedInput.updateOptions(this.options);
        maskedInput.unmaskedValue = this.value;

        if (this.textFieldRef) {
          this.textFieldRef.lazyValue = maskedInput.value;
        }

        maskedInput.updateValue();
      }
    );

    this.$watch(
      () => {
        return this.isCompleted;
      },
      (isCompleted) => {
        this.$emit("change:complete", isCompleted, maskedInput.unmaskedValue);
      },
      { immediate: true }
    );

    this.$once("hook:beforeDestroy", () => {
      inputEl.removeEventListener("blur", onBlur);
      maskedInput.destroy();
    });
  }
}
